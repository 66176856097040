import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./store"

const finalAnswers = {
    1: "fp academy",
    2: "one day recruitment",
    3: "to nie bug to feature",
}

const getRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export interface GameState {
    questions: Array<{
        question: string
        answers: Array<{
            answer: string
            isCorrect: boolean
        }>
    }>
    correctAnswer: boolean
    allLettersReveald: boolean
    allLettersRevealed: boolean
    currentQuestion: number
    finalAnswer: string
    revealdLetters: Array<string>
    lamaText: string
    popup: boolean
}

const initialState: GameState = {
    questions: [],
    correctAnswer: false,
    allLettersReveald: false,
    allLettersRevealed: false,
    currentQuestion: 0,
    finalAnswer: "",
    revealdLetters: [],
    lamaText: "",
    popup: true,
}

export const gameSlice = createSlice({
    name: "game",
    initialState,
    reducers: {
        setup: state => {
            // state.questions = action.payload
            state.finalAnswer = finalAnswers[getRandomNumber(1, 3)]
        },

        nextQuestion: state => {
            state.currentQuestion++
        },

        setCorrectAnswer: (state, action: PayloadAction<any>) => {
            state.correctAnswer = true
        },

        revealLetter: state => {
            let letterToReveal = ""

            const findUnique = (str: string) => {
                return [...str].reduce((acc, curr) => {
                    return acc.includes(curr) ? acc : acc + curr
                }, "")
            }
            const uniqueLetters = findUnique(state.finalAnswer)
                .replace(/\s/g, "")
                .split("")
            uniqueLetters.sort(() => Math.random() - 0.5)

            uniqueLetters.forEach(letter => {
                if (!state.revealdLetters.includes(letter)) {
                    letterToReveal = letter
                }
            })
            state.revealdLetters.push(letterToReveal)

            if (state.revealdLetters.length === uniqueLetters.length) {
                state.allLettersReveald = true
            }
        },

        setLamaText: (state, action: PayloadAction<string>) => {
            state.lamaText = action.payload
        },

        revealAllLetters: state => {
            state.allLettersReveald = true
        },

        closePopup: state => {
            state.popup = false
        },

        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
})

// Action creators are generated for each case reducer function
export const {
    setup,
    setLamaText,
    revealAllLetters,
    nextQuestion,
    revealLetter,
    closePopup,
} = gameSlice.actions

export const selectFinalAnswer = (state: RootState) => state.game.finalAnswer
export const selectLamaText = (state: RootState) => state.game.lamaText
export const selectPopup = (state: RootState) => state.game.popup
export const selectAllLettersReveald = (state: RootState) =>
    state.game.allLettersReveald
export const selectRevealdLetter = (state: RootState) =>
    state.game.revealdLetters

export default gameSlice.reducer
