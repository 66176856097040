import React from "react"
import { Provider } from "react-redux"
import { store } from "./src/store/store"
import PopUp from "./src/components/common/pop-up/PopUp"

const ReduxWrapper = ({ element }) => (
    <Provider store={store}>
        <>
            <PopUp />
            {element}
        </>
    </Provider>
)
export default ReduxWrapper
