import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const StyledButton = styled.button`
    color: #fff;
    padding: 10px 20px;
    background-color: ${cssVariables.colors.main};
    font-weight: 600;
    text-transform: uppercase;

    ${breakpoints.lg} {
        /* font-size: ${cssVariables.fonts.big}; */
    }

    &:disabled {
        background-color: #dadada;
        cursor: not-allowed;
    }
`

export { StyledButton }
