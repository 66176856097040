import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Wrapper = styled.div<{ open: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    /* background-color: ${cssVariables.colors.dark}; */
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
    opacity: ${({ open }) => (open ? "1" : "0")};
    transition: 0.3s;

    &:after {
        content: "";
        position: absolute;
        width: calc(100vw - 20%);
        height: calc(100vh - 50%);
        background-color: #fff;
        z-index: -1;
        border: 4px solid ${cssVariables.colors.dark};
        max-height: 300px;

        ${breakpoints.lg} {
            width: calc(100vw - 40%);
            height: calc(100vh - 50%);
            max-height: 5000px;
        }
    }
`

const Content = styled.div`
    max-width: calc(100vw - 30%);
    max-height: calc(100vh - 60%);
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakpoints.lg} {
        max-width: calc(100vw - 50%);
        max-height: calc(100vh - 60%);
    }
`

const Header = styled.h2`
    font-weight: 700;
    text-align: center;

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.xBig};
    }
`

const CheckboxWrapper = styled.div<{ checked: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 30px 0;

    svg {
        position: absolute;
        left: 3px;
        opacity: ${({ checked }) => (checked ? 1 : 0)};
        transition: 0.3s;
    }
`

const Checkbox = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 1px solid ${cssVariables.colors.dark};
`

const Label = styled.label`
    margin-left: 15px;
    cursor: pointer;

    a {
        color: ${cssVariables.colors.main};
        font-weight: 700;
    }
`

export { Wrapper, Header, Content, Checkbox, CheckboxWrapper, Label }
