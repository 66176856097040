const colors = {
    main: "#FFA000",
    gray: "#DADADA",
    grayDark: "#616161",
    dark: "#242425",
    error: "#FF6000",
    success: "#3BAA25",
}

const fonts = {
    small: "12px",
    normal: "14px",
    medium: "16px",
    big: "24px",
    xBig: "34px",
}

export const cssVariables = {
    colors,
    fonts,
    gridWidth: "1440px",
}
