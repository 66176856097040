import React from "react"
import * as S from "./styled"

const Button = ({ onClick, text, disabled }: Props) => {
    return (
        <S.StyledButton
            disabled={disabled}
            onClick={onClick}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    )
}

interface Props {
    onClick: () => void
    text: string
    disabled?: true | boolean
}

export default Button
