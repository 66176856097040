import React, { useEffect, useState } from "react"
import * as S from "./styled"
import Button from "../button/Button"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { closePopup, selectPopup } from "../../../store/gameSlice"

const PopUp = () => {
    const [accepted, setAccepted] = useState(false)
    const dispatch = useAppDispatch()
    const popup = useAppSelector(selectPopup)

    useEffect(() => {
        if (popup) {
            console.log("elo")
            document.body.classList.add("menu-open")
        } else {
            document.body.classList.remove("menu-open")
        }
    }, [popup])

    return (
        <S.Wrapper open={popup}>
            <S.Content>
                <S.Header
                    dangerouslySetInnerHTML={{
                        __html: "Zaakceptuj regulamin i przejdź do gry 😊",
                    }}
                />
                <S.CheckboxWrapper checked={accepted}>
                    <svg
                        width="13.193"
                        height="11.066"
                        viewBox="0 0 13.193 11.066"
                    >
                        <path
                            d="M640,4079.841l3.964,3.944,6.411-8.16"
                            transform="translate(-638.586 -4074.221)"
                            fill="none"
                            stroke="#ffa000"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                    </svg>

                    <S.Checkbox
                        id="acceptance"
                        type="checkbox"
                        onChange={() => setAccepted(accepted => !accepted)}
                    />
                    <S.Label htmlFor="acceptance">
                        Akceptuje{" "}
                        <a href="https://www.fplay.pl/regulamin.pdf">
                            regulamin
                        </a>{" "}
                        konkursu
                    </S.Label>
                </S.CheckboxWrapper>

                <Button
                    text="przejdź do gry"
                    disabled={!accepted}
                    onClick={() => {
                        dispatch(closePopup())
                    }}
                />
            </S.Content>
        </S.Wrapper>
    )
}

export default PopUp
